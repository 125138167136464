/*global $*/
/*eslint no-undef: "error"*/

export default class CartButton {
    classButton = 'cart-small--button'
    classButtonOpen = 'cart-small-open'

    classBlock = 'cart--block'
    classBlockOpen = 'header__cart---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classButtonOpen)
            $(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })

        $(document).mouseup( function(e){
            if ($(`.${self.classButtonOpen}`).length) {
                if (($(`.${self.classBlock}`).has(e.target).length === 0) && ($(`.${self.classButton}`).has(e.target).length === 0)) {
                    $(`.${self.classButton}`).removeClass(self.classButtonOpen)
                    $(`.${self.classBlock}`).removeClass(self.classBlockOpen)
                }
            }
        })
    }
}
